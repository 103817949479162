import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import transitionLogo from '../../images/monchan.png';
import './WhiteTransition.css';

const WhiteTransition = ({ children, inProp }) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={inProp}
      timeout={500}
      classNames="white-transition"
      unmountOnExit
    >
      <div ref={nodeRef} className="white-transition-overlay">
        <img src={transitionLogo} alt="Transition Logo" className="transition-logo" />
        {children}
      </div>
    </CSSTransition>
  );
};

export default WhiteTransition;
