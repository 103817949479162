import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import arTranslations from './ar.json';
import bnTranslations from './bn.json';
import csTranslations from './cs.json';
import daTranslations from './da.json';
import deTranslations from './de.json';
import elTranslations from './el.json';
import enTranslations from './en.json';
import esTranslations from './es.json';
import faTranslations from './fa.json';
import fiTranslations from './fi.json';
import frTranslations from './fr.json';
import heTranslations from './he.json';
import hiTranslations from './hi.json';
import huTranslations from './hu.json';
import idTranslations from './id.json';
import itTranslations from './it.json';
import jaTranslations from './ja.json';
import jvTranslations from './jv.json';
import koTranslations from './ko.json';
import msTranslations from './ms.json';
import myTranslations from './my.json';
import nlTranslations from './nl.json';
import noTranslations from './no.json';
import paTranslations from './pa.json';
import plTranslations from './pl.json';
import ptTranslations from './pt.json';
import roTranslations from './ro.json';
import ruTranslations from './ru.json';
import svTranslations from './sv.json';
import swTranslations from './sw.json';
import taTranslations from './ta.json';
import teTranslations from './te.json';
import thTranslations from './th.json';
import trTranslations from './tr.json';
import ukTranslations from './uk.json';
import urTranslations from './ur.json';
import viTranslations from './vi.json';
import zhHANSTranslations from './zh-HANS.json';
import zhHANTTranslations from './zh-HANT.json';

const getBrowserLanguage = () => {
  const supportedLanguages = [
    'en', 'ja', 'ar', 'bn', 'cs', 'da', 'de', 'el', 'es', 'fa',
    'fi', 'fr', 'he', 'hi', 'hu', 'id', 'it', 'jv', 'ko', 'ms',
    'my', 'nl', 'no', 'pa', 'pl', 'pt', 'ro', 'ru', 'sv', 'sw',
    'ta', 'te', 'th', 'tr', 'uk', 'ur', 'vi', 'zh_HANS', 'zh_HANT'
  ];

  // 言語コードの最初の2文字を取得 (例: "ja-JP" -> "ja")
  const browserLang = navigator.language || navigator.userLanguage;
  const shortLang = browserLang.split('-')[0];

  return supportedLanguages.includes(shortLang) ? shortLang : 'en';
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ar: { translation: arTranslations },
      bn: { translation: bnTranslations },
      cs: { translation: csTranslations },
      da: { translation: daTranslations },
      de: { translation: deTranslations },
      el: { translation: elTranslations },
      en: { translation: enTranslations },
      es: { translation: esTranslations },
      fa: { translation: faTranslations },
      fi: { translation: fiTranslations },
      fr: { translation: frTranslations },
      he: { translation: heTranslations },
      hi: { translation: hiTranslations },
      hu: { translation: huTranslations },
      id: { translation: idTranslations },
      it: { translation: itTranslations },
      ja: { translation: jaTranslations },
      jv: { translation: jvTranslations },
      ko: { translation: koTranslations },
      ms: { translation: msTranslations },
      my: { translation: myTranslations },
      nl: { translation: nlTranslations },
      no: { translation: noTranslations },
      pa: { translation: paTranslations },
      pl: { translation: plTranslations },
      pt: { translation: ptTranslations },
      ro: { translation: roTranslations },
      ru: { translation: ruTranslations },
      sv: { translation: svTranslations },
      sw: { translation: swTranslations },
      ta: { translation: taTranslations },
      te: { translation: teTranslations },
      th: { translation: thTranslations },
      tr: { translation: trTranslations },
      uk: { translation: ukTranslations },
      ur: { translation: urTranslations },
      vi: { translation: viTranslations },
      zh_HANS: { translation: zhHANSTranslations },
      zh_HANT: { translation: zhHANTTranslations }
    },
    lng: getBrowserLanguage(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
