import { useState, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MarkdownCanvas from '../canvas/MarkdownCanvas';
import MindmapCanvas from '../canvas/MindmapCanvas';
import ClassDiagramCanvas from '../canvas/ClassDiagramCanvas';
import banner from '../../images/banner.png';
import { API_URL } from '../../App';
import '../../App.css';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  CircularProgress
} from '@mui/material';

const ResultScreen = ({ taskId, setTaskID }) => {
  const { t } = useTranslation();
  const [record, setRecord] = useState({"status": "not started"});
  const [contentHeight, setContentHeight] = useState(0);
  const [selectedContent, setSelectedContent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const canvasRef = useRef(null);
  const rootRef = useRef(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const startTimeRef = useRef(null);

  useEffect(() => {
    // Set height of the content area.
    const calculateContentHeight = () => {
      const windowHeight = window.innerHeight;
      const header = document.querySelector('header');
      const footer = document.querySelector('footer');
      const newHeight = windowHeight - header.offsetHeight - footer.offsetHeight;
      setContentHeight(newHeight);
    };

    // Initializing height
    calculateContentHeight();

    // Initializing rootRef. Called only once.
    if (canvasRef.current && !rootRef.current) {
      rootRef.current = createRoot(canvasRef.current);
    }

    // Update content height when window is resized.
    window.addEventListener('resize', calculateContentHeight);
    return () => window.removeEventListener('resize', calculateContentHeight);
  }, []);

  useEffect(() => {
    // Fetch status of the task.
    const fetchStatus = async () => {
      try {
        const response = await fetch(`${API_URL}/status/${taskId}`);
        const data = await response.json();
        setRecord(data);

        // Update elapsed time
        if (!startTimeRef.current) {
          startTimeRef.current = new Date();
        }
        const currentTime = new Date();
        const elapsed = Math.floor((currentTime - startTimeRef.current) / 1000);
        setElapsedTime(elapsed);
      } catch (error) {
        alert('Error while fetching: ' + error.message);
      }
    };

    const intervalId = setInterval(() => {
      if (record.status !== 'done' && record.status !== 'failed') {
        fetchStatus();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [taskId, record]);

  useEffect(() => {
    // Rendering result for selected item on sidebar.
    if (selectedContent && rootRef.current) {
      const contentType = {
        ideas: <MarkdownCanvas type={selectedContent.key} input={selectedContent.input} output={selectedContent.data} />,
        mindmap: <MindmapCanvas data={selectedContent.data} />,
        class_diagram: <ClassDiagramCanvas data={selectedContent.data} />,
        evaluation: <MarkdownCanvas type={selectedContent.key} input={selectedContent.input} output={selectedContent.data} />
      };

      const content = contentType[selectedContent.key];
      if (content) {
        rootRef.current.render(content);
      }
    }
  }, [selectedContent]);

  const renderContentMenu = () => {
    // Show menu on sidebar.
    const menuItems = ['ideas', 'mindmap', 'class_diagram', 'evaluation'];
    const menuIcon = {
      ideas: <TipsAndUpdatesIcon />,
      mindmap: <AccountTreeIcon />,
      class_diagram: <FolderZipIcon />,
      evaluation: <AssessmentIcon />
    };

    return menuItems.map((key, index) => {
      const isSelected = selectedContent && selectedContent.key === key;
      const hasContent = record.output && record.output[key];

      return (
        <li key={index}>
          <Button
            onClick={() => {
              if (hasContent) {
                setSelectedContent({ 
                  key, 
                  input: record.input, 
                  data: record.output[key] 
                });
              }
            }}
            startIcon={menuIcon[key]}
            variant="contained"
            color={isSelected ? "success" : "secondary"}
            disabled={!hasContent}
            sx={{
              opacity: hasContent ? 1 : 0.5,
              '&.Mui-disabled': {
                color: 'rgba(255, 255, 255, 0.9)',
                boxShadow: 'none',
                backgroundColor: 'rgba(64, 64, 64, 0.7)',
              },
              ...(hasContent && {
                animation: 'glow 1.5s ease-in-out',
                animationIterationCount: 1,
                '@keyframes glow': {
                  '0%': {
                    boxShadow: '0 0 5px #fff, 0 0 10px #fff, 0 0 15px #0ff'
                  },
                  '50%': {
                    boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 30px #0ff'
                  },
                  '100%': {
                    boxShadow: 'none'
                  }
                }
              })
            }}
          >
            {t(`result_sidebar.${key}`)}
          </Button>
        </li>
      );
    });
  };

  const handleReset = () => {
    setRecord({"status": "not started"});
    setTaskID(null);
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  return (
    <div
      className="result-screen"
      style={{
        height: `${contentHeight}px`,
        top: `${document.querySelector('header').offsetHeight}px`
      }}
    >
      {/* Sidebar */}
      <div className="result-sidebar">
        <div className="banner-result">
          <img src={banner} alt="monju logo" />
        </div>
        <div className="result-reset-button">
          <Button
            onClick={() => setOpenDialog(true)}
            startIcon={<HomeIcon />}
            variant="contained"
            color="primary"
          >
            {t('result_sidebar.reset')}
          </Button>
        </div>
        <div className="result-menu">
          <p id="menu-title">{t('result_sidebar.content')}</p>
          <ul className="result-menu-list">
            {renderContentMenu()}
          </ul>
        </div>
        <div className="result-task-id">
          <p>Task ID: {taskId}</p>
        </div>
      </div>
      {/* Main area */}
      <div className="result-main">
        <div className="progress-bar">
          {record.status === 'failed' ? (
            <span className="error-message">{t('running.exe_error')}</span>
          ) : record.status !== 'done' && (
            <>
              <CircularProgress size={24} />
              <span>{t('result_main.header')} - </span>
              <span id="elapsed-time">{elapsedTime}</span>
              <span>{t('running.elapsed_time')}</span>
            </>
          )}
        </div>
        <h1>
          {record.input && record.input.theme ? record.input.theme : ""}
        </h1>
        <div
          className="result-canvas"
          ref={canvasRef}
        >
          {/* Canvas to show results here */}
        </div>
      </div>

      {/* Dialog to confirm if user wants to reset the task */}
      <Dialog
        open={openDialog}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('confirm_reset.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('confirm_reset.message')}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '24px' }}>
          <Button onClick={handleReset} variant="contained" color="secondary">
            {t('confirm_reset.yes')}
          </Button>
          <Button onClick={handleCancel} variant="contained" autoFocus>
            {t('confirm_reset.no')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResultScreen;
