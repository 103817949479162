import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactFlow, Controls, useNodesState, useEdgesState } from '@xyflow/react';
import ClassDiagramDownloadButton from './ClassDiagramDownloadButton';
import KJNode from './KJNode';
import '@xyflow/react/dist/style.css';
import '../../App.css';

const nodeTypes = {
  kjNode: KJNode,
};

const ClassDiagramCanvas = ({ data }) => {

  const { t } = useTranslation();
  const reactFlowInstance = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(data.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(data.edges);

  return (
    <div className="class-diagram-canvas">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        ref={reactFlowInstance}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        fitView
      >
        <Controls />
        <ClassDiagramDownloadButton buttonText={t('result_main.save_image')} />
      </ReactFlow>
    </div>
  );
};

export default ClassDiagramCanvas;
