import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import StormIcon from '@mui/icons-material/Storm';
import HelpIcon from '@mui/icons-material/Help';
import banner from '../../images/banner.png';
import { API_URL } from '../../App';
import '../../App.css';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle 
} from '@mui/material';

const InputScreen = ({ onTaskCreated }) => {
  const { t, i18n } = useTranslation();
  const [theme, setTheme] = useState('');
  const [numIdeas, setNumIdeas] = useState(10);
  const [freedom, setFreedom] = useState(0.7);
  const [openDialog, setOpenDialog] = useState(false);
  const max_theme_length = 300;

  const handleStartClick = () => {
    if (theme.length < 1) {
      alert(t('check.no_theme_error'));
    } else if (theme.length > max_theme_length) {
      alert(
        t('check.long_theme_error') + 
        "\nLimit: " + max_theme_length + 
        "\nYour input: " + theme.length
      );
    } else {
      setOpenDialog(true);
    }
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  // POST request to the server
  const handlePost = async () => {
    setOpenDialog(false);
    try {
      const requestBody = {
        theme,
        ideas: numIdeas,
        freedom,
        language: i18n.language,
      };

      const response = await fetch(`${API_URL}/new`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      let taskId = data.task_id;
      if (taskId && typeof taskId === 'string') {
          onTaskCreated(taskId);
      } else {
          alert(t('running.taskid_error') + taskId);
          taskId = null;
      }
    } catch (error) {
      console.error('Post Error:', error);
      alert(t('running.fetch_error') + error.message);
    }
  };

  return (
    // Control panel for input
    <div className="control-panel">
      <div className="banner-input">
        <img src={banner} alt="monju logo" />
      </div>
      <div className="control-panel-item">
        <div className="label-usage">
          <p>
            <HelpIcon className="icon-help" sx={{ fontSize: 16, marginRight: 1 }} />
            {t('input.usage')}
          </p>
          <p>
            {t('input.limit')} {max_theme_length}
          </p>
        </div>
        <div className="input-prompt">
          <input
            type="text"
            id="prompt"
            placeholder={t('input.theme')}
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
          />
        </div>
        <div className="input-ideas">
          <label htmlFor="ideas-slider">
            <TipsAndUpdatesIcon className="icon-bulb" sx={{ fontSize: 16, marginRight: 1 }} />
            {t('input.num_ideas')}: 
          </label>
          <input 
            type="range" 
            id="ideas-slider" 
            className="input-slider" 
            min="5" 
            max="20" 
            step="1" 
            value={numIdeas}
            onChange={(e) => setNumIdeas(Number(e.target.value))}
          />
          <span id="ideas-value">{numIdeas}</span>
        </div>
        <div className="input-freedom">
          <label htmlFor="freedom-slider">
            <StormIcon className="icon-storm" sx={{ fontSize: 16, marginRight: 1 }} />
            {t('input.freedom')}: 
          </label>
          <input 
            type="range" 
            id="freedom-slider" 
            className="input-slider" 
            min="0" 
            max="1" 
            step="0.1" 
            value={freedom}
            onChange={(e) => setFreedom(Number(e.target.value))}
          />
          <span id="freedom-value">{freedom.toFixed(1)}</span>
        </div>
        <div className="input-start-button">
          <button 
            id="start-button" 
            onClick={handleStartClick}
          >
            {t('input.start')}
          </button>
        </div>
      </div>

      {/* Dialog for confirmation before posting */}
      <Dialog
        open={openDialog}
        onClose={handleCancel}
        aria-labelledby="input-dialog-title"
        aria-describedby="input-dialog-description"
      >
        <DialogTitle id="input-dialog-title">
          {t('confirm_post.title')}
        </DialogTitle>
        <DialogContent sx={{ padding: '10px' }}>
          <DialogContentText id="input-dialog-description">
            {t('confirm_post.message')}
          </DialogContentText>
          <div className="input-dialog-theme">
            <EditIcon sx={{ fontSize: 14, marginRight: 1 }} />
            {t('input.theme')}: <span>{theme}</span>
          </div>
          <div className="input-dialog-ideas">
            <TipsAndUpdatesIcon sx={{ fontSize: 14, marginRight: 1 }} />
            {t('input.num_ideas')}: <span>{numIdeas}</span>
          </div>
          <div className="input-dialog-freedom">
            <StormIcon sx={{ fontSize: 14, marginRight: 1 }} />
            {t('input.freedom')}: <span>{freedom}</span>
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '24px' }}>
          <Button onClick={handlePost} variant="contained" color="secondary">
            {t('confirm_post.yes')}
          </Button>
          <Button onClick={handleCancel} variant="contained" autoFocus>
            {t('confirm_post.no')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InputScreen;
