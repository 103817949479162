import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const Background = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const init = () => {
      // 1. 設定
      const particles = 4000;
      const speed = 20;
      const dim = 200; // パーティクル空間の次元

      // 2. Three.jsの初期化
      const canvas = canvasRef.current;
      const renderer = new THREE.WebGLRenderer({ canvas, preserveDrawingBuffer: true, alpha: true });
      renderer.autoClearColor = false; // 軌跡のため

      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 1000);

      // 3. 星の生成
      const vertices = [];
      for (let i = 0; i < particles; i++) {
        const x = dim * 8 * (Math.random() - 0.5);
        const y = dim * 2 * (Math.random() - 0.5);
        const z = -dim * Math.random();
        vertices.push(x, y, z);
      }

      const starGeo = new THREE.BufferGeometry();
      starGeo.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

      // 4. 星の見た目の設定
      const starMat = new THREE.PointsMaterial({
        color: 0xffffff,
        size: 0.5,
        transparent: true, // 軌跡のため
        depthTest: false // 軌跡のため
      });
      const starPoints = new THREE.Points(starGeo, starMat);

      // 5. 軌跡効果の設定
      const fadeGeo = new THREE.PlaneGeometry(1, 1);
      const fadeMat = new THREE.MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 0.4,
      });
      const fadePlate = new THREE.Mesh(fadeGeo, fadeMat);
      fadePlate.material.renderOrder = -1; // 重要！
      fadePlate.position.z = -0.1;

      scene.add(fadePlate);
      scene.add(starPoints);

      // Add resize event listener
      const handleResize = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        renderer.setSize(width, height, false);
        renderer.setPixelRatio(window.devicePixelRatio);  // タブレット対応
      };

      window.addEventListener('resize', handleResize);
      window.addEventListener('orientationchange', handleResize);  // タブレット対応

      // Initial resize
      handleResize();

      // Animation loop
      const draw = () => {
        // 星の位置更新
        starGeo.attributes.position.needsUpdate = true;
        const positions = starGeo.attributes.position.array;
        for (let i = 0; i < positions.length; i += 3) {
          const z = positions[i + 2];
          if (z >= 0) {
            // 画面外に出た星を再配置
            positions[i] = dim * 8 * (Math.random() - 0.5);
            positions[i + 1] = dim * 2 * (Math.random() - 0.5);
            positions[i + 2] = -dim;
          } else {
            // 星を前に移動
            positions[i + 2] += -speed / positions[i + 2];
          }
        }

        // シーンの描画
        renderer.render(scene, camera);
        requestAnimationFrame(draw);
      };

      requestAnimationFrame(draw);

      // Cleanup function
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleResize);  // タブレット対応
      };
    };

    init();
  }, []);

  return (
    <canvas ref={canvasRef} className="background-canvas" />
  );
};

export default Background;
