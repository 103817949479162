import { useState, useEffect } from 'react';
import LanguageSelector from './components/screen/LanguageSelector';
import Background from './components/scene/Background';
import WhiteTransition from './components/scene/WhiteTransition';
import InputScreen from './components/screen/InputScreen';
import ResultScreen from './components/screen/ResultScreen';
import Footer from './components/screen/Footer';
import transitionLogo from './images/monchan.png';
import './App.css';

export const API_URL = 'https://monju.ai/api';
// export const API_URL = 'http://192.168.0.129:8000/api';

function App() {
  const [taskID, setTaskID] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isImagePreloaded, setIsImagePreloaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setIsImagePreloaded(true);
    img.src = transitionLogo;
  }, []);

  const handleTaskCreated = (newTaskID) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setTaskID(newTaskID);
      setIsTransitioning(false);
    }, 1500);
  };

  const handleResetTask = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setTaskID(null);
      setIsTransitioning(false);
    }, 1500);
  };

  return (
    <div className="App">
      <Background />
      <LanguageSelector />
      <div className="main-content">
        {isImagePreloaded && (
          <WhiteTransition inProp={isTransitioning} />
        )}
        {!isTransitioning && taskID === null && (
          <InputScreen onTaskCreated={handleTaskCreated} />
        )}
        {!isTransitioning && taskID !== null && (
          <ResultScreen taskId={taskID} setTaskID={handleResetTask} />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default App;
