import React from 'react';
import { Handle, Position } from '@xyflow/react';
import './KJNode.css';

const KJNode = ({ data, isConnectable }) => {
  return (
    <div className="kj-node">
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div className="kj-node__header">
        <strong>{data.label}</strong>
      </div>
      <div className="kj-node__content">
        {data.items.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default KJNode;
