import { useTranslation } from 'react-i18next';
import '../../locales/i18n';
import '../../App.css';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <header>
      <div className="input-language">
        <span>🌐LANGUAGE: </span>
        <select id="language-select" onChange={changeLanguage} value={i18n.language}>
          <option value="en">English</option>
          <option value="ja">日本語</option>
          <option value="ar">العربية</option>
          <option value="bn">বাংলা</option>
          <option value="cs">Čeština</option>
          <option value="da">Dansk</option>
          <option value="de">Deutsch</option>
          <option value="el">Ελληνικά</option>
          <option value="es">Español</option>
          <option value="fa">فارسی</option>
          <option value="fi">Suomi</option>
          <option value="fr">Français</option>
          <option value="he">עברית</option>
          <option value="hi">हिन्दी</option>
          <option value="hu">Magyar</option>
          <option value="id">Bahasa Indonesia</option>
          <option value="it">Italiano</option>
          <option value="jv">Basa Jawa</option>
          <option value="ko">한국어</option>
          <option value="ms">Bahasa Melayu</option>
          <option value="my">မြန်မာစာ</option>
          <option value="nl">Nederlands</option>
          <option value="no">Norsk</option>
          <option value="pa">ਪੰਜਾਬੀ</option>
          <option value="pl">Polski</option>
          <option value="pt">Português</option>
          <option value="ro">Română</option>
          <option value="ru">Русский</option>
          <option value="sv">Svenska</option>
          <option value="sw">Kiswahili</option>
          <option value="ta">தமிழ்</option>
          <option value="te">తెలుగు</option>
          <option value="th">ไทย</option>
          <option value="tr">Türkçe</option>
          <option value="uk">Українська</option>
          <option value="ur">اردو</option>
          <option value="vi">Tiếng Việt</option>
          <option value="zh_HANS">简体中文</option>
          <option value="zh_HANT">繁體中文</option>
          {/* Add more language options as needed */}
        </select>
      </div>
    </header>
  );
}

export default LanguageSelector;
