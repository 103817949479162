import React from 'react';
import {
  Panel,
  useReactFlow,
  getViewportForBounds,
} from '@xyflow/react';
import { toPng } from 'html-to-image';

const ClassDiagramDownloadButton = ({ buttonText }) => {
  const { getNodes, getEdges,getNodesBounds } = useReactFlow();

  const downloadImage = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const divElement = document.querySelector('.class-diagram-canvas');
    const divSize = divElement.getBoundingClientRect();
    const imageWidth = divSize.width;
    const imageHeight = divSize.height;

    const nodesBounds = getNodesBounds(getNodes(), getEdges());
    const viewport = getViewportForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.5,
      2,
    );

    setTimeout(() => {
      toPng(document.querySelector('.react-flow__viewport'), {
        backgroundColor: 'transparent',
        width: imageWidth,
        height: imageHeight,
        style: {
          width: `${imageWidth}px`,
          height: `${imageHeight}px`,
          transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
        },
      }).then((dataUrl) => {
        const a = document.createElement('a');
        a.setAttribute('download', 'class-diagram.png');
        a.setAttribute('href', dataUrl);
        a.click();
      });
    }, 100);
  };

  return (
    <Panel position="bottom-right" className="save-class-diagram-container">
      <button className="save-image-button" onClick={downloadImage}>
        {buttonText}
      </button>
    </Panel>
  );
};

export default ClassDiagramDownloadButton;
