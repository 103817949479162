import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Network } from 'vis-network';
import { DataSet } from 'vis-data';
import 'vis-network/styles/vis-network.css';
import '../../App.css';

const MindmapCanvas = ({ data }) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const networkRef = useRef(null);
  const [nodes, setNodes] = useState(new DataSet(data.nodes));
  const [edges, setEdges] = useState(new DataSet(data.edges));
  // const [editingNode, setEditingNode] = useState(null);

  useEffect(() => {
    if (!data || !containerRef.current) return;

    const options = {
      autoResize: true,
      interaction: {
        navigationButtons: true,
        hover: true,
        keyboard: true
      },
      edges: {
        color: {
          color: "#97C2FC",
          hover: "red",
          highlight: "red"
        }
      },
      nodes: {
        shape: 'box',
        margin: 10,
        widthConstraint: {
          maximum: 250
        },
        font: {
          size: 18,
          multi: true
        }
      },
      physics: {
        enabled: false
      }
    };

    networkRef.current = new Network(containerRef.current, { nodes, edges }, options);

    return () => {
      if (networkRef.current) {
        networkRef.current.destroy();
      }
    };
  }, [data]);

  const saveAsImage = () => {
    if (networkRef.current) {
      const canvas = networkRef.current.canvas.frame.canvas;
      const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      const link = document.createElement('a');
      link.download = 'mindmap.png';
      link.href = image;
      link.click();
    }
  };

  return (
    <>
      <div ref={containerRef} className="mindmap-canvas"></div>
      <div className="save-mindmap-container">
        <button
          onClick={saveAsImage}
          className="save-image-button"
        >
          {t('result_main.save_image')}
        </button>
      </div>
    </>
  );
};

export default MindmapCanvas;
